import { securedWrap } from '@mop/shared/utils/securedWrap';
import type {
  CmsStoryModelRef,
  CmsStoryParams,
  CmsStoriesParams,
  CmsStoryListModelRef,
  CmsStoryResponseData,
  CmsStoryListModel,
  CmsStoryConfigName,
} from '@/types/cms';
import { cmsStoryModel, cmsStoryListModel } from '@/models';

type LoadingState = {
  getStory: boolean;
  getStories: boolean;
  getCmsStoryByConfigId: boolean;
  loading: boolean;
};

const COMPANY_SLUG_PREFIX = 'theme-company';

export function useMopCms() {
  const nuxtApp = useNuxtApp();
  const { $gtm, $apiCms } = nuxtApp;
  const cmsStoryModelRef: CmsStoryModelRef | Ref<null> = ref(cmsStoryModel(null));
  const cmsStoryListModelRef: CmsStoryListModelRef | Ref<null> = ref(cmsStoryListModel(null));
  const loadingRef: Ref<LoadingState> = ref({
    getStory: false,
    getStories: false,
    getCmsStoryByConfigId: false,
    loading: computed(() => isLoading(loadingRef)),
  });

  async function getCmsStory(slug: string, params: CmsStoryParams = {}) {
    loadingRef.value.getStory = true;

    cmsStoryModelRef.value = cmsStoryModel(
      await $apiCms.getStory(`${COMPANY_SLUG_PREFIX}${slug}`, {
        resolve_relations: 'Card.linkPreview',
        ...params,
      }),
    );
    loadingRef.value.getStory = false;
  }

  async function getCmsStoryByUuid(uuid: string, params: CmsStoryParams = {}) {
    loadingRef.value.getStory = true;
    params.find_by = 'uuid';
    cmsStoryModelRef.value = cmsStoryModel(await $apiCms.getStory(uuid, params));
    loadingRef.value.getStory = false;
  }

  async function getCmsStoryByConfigId(
    configName: CmsStoryConfigName,
    configValue: unknown,
    cmsParams: CmsStoriesParams = {},
  ) {
    loadingRef.value.getCmsStoryByConfigId = true;
    const params: CmsStoriesParams = {
      filter_query: {
        [configName]: {
          in: configValue,
        },
      },
      ...cmsParams,
    };
    const storyList: CmsStoryListModel = cmsStoryListModel(await $apiCms.getStories(undefined, params));

    if (!storyList.isInitialized() || storyList.hasError()) {
      loadingRef.value.getCmsStoryByConfigId = false;
      return;
    }
    const response: CmsStoryResponseData = storyList.getStoryModelList()?.[0]?.getResponse() || {
      error: {
        code: constants.ERROR_CODE.NOT_FOUND,
      },
    };
    cmsStoryModelRef.value = cmsStoryModel(response);
    loadingRef.value.getCmsStoryByConfigId = false;
  }

  async function getCmsStoryList(slug?: string, params: CmsStoriesParams = {}) {
    loadingRef.value.getStories = true;
    cmsStoryListModelRef.value = cmsStoryListModel(await $apiCms.getStories(`${COMPANY_SLUG_PREFIX}${slug}`, params));
    loadingRef.value.getStories = false;
  }

  function trackPageView(pageName: string) {
    if (cmsStoryModelRef.value?.hasErrorNotFound()) {
      throw createError({
        statusCode: 404,
        statusMessage: 'PAGE_NOT_FOUND',
      });
    } else {
      $gtm.trackPageView({
        pageType: pageName,
      });
    }
  }

  return securedWrap({
    getCmsStory,
    getCmsStoryByUuid,
    getCmsStoryList,
    getCmsStoryByConfigId,
    trackPageView,
    cmsStoryModelRef,
    cmsStoryListModelRef,
    loadingRef,
  });
}
